body {
  background-color: #000;
}

.wolrd img {
  margin: auto;
  width: 50%;
  display: block;
}

.wolrd h1 {
  font-size: 100px;
  margin: auto;
  font-family: Arial, sans-serif;
  font-weight: bold;
  color: white;
  display: block;
  text-align: center;
}

.wolrd h2 {
  font-size: 30px;
  margin: auto;
  font-family: Arial, sans-serif;
  font-weight: bold;
  color: white;
  display: block;
  text-align: center;
}

.nav {
  position: relative;
  text-align: center;
}

.navigation-next {
  position: fixed;
  right: 0;
  width: 50%;
  height: 500px;
}

.navigation-prev {
  position: fixed;
  left: 0; 
  width: 50%;
  height: 500px;
}

.arrow {
  width: 0; 
  height: 0; 
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  position: absolute;
}

.arrow-right {
  border-left: 25px solid #fff;
  right: 0;
}

.arrow-left {
  border-right: 25px solid #fff; 
  left: 0;
}

.container {
  margin: 50% auto;
  font-size: 30px;
  position: relative;
  width: 15em;
}

.input {
  font-family: Arial, sans-serif;
  padding: 4px;
  width: 15%;
  margin-bottom: 20px;
}
.button {
  font-family: Arial, sans-serif;
  padding: 5px;
  width: 4%;
  border: solid white 2px;
  color: white;
  background-color: black;
}

@media (max-width: 800px) {

  .wolrd h1 {
    font-size: 30px;
    font-family: Arial, sans-serif;
    color: white;
  }
  .wolrd h2 {
    font-size: 12px;
    font-family: Arial, sans-serif;
    color: white;
  }

  .arrow {
    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    
    position: absolute;
  }

  .arrow-right {
    border-left: 10px solid #fff;
    right: 10px;
  }

  .arrow-left {
    border-right: 10px solid #fff; 
    left: 10px;
  }

  .container {
    margin: 100% auto;
    font-size: 10px;
    position: relative;
    width: 15em;
  }

  .input {
    font-family: Arial, sans-serif;
    padding: 4px;
    width: 25%;
    margin-bottom: 20px;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .button {
    font-family: Arial, sans-serif;
    padding: 3px;
    width: 15%;
    border: solid white 2px;
    color: white;
    background-color: black;
  }

}
